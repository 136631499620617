import { campaignInvoicesMapping, campaignChargesMapping, paymentStatusMapping, campaignStatusMapping} from '../misc'
import { agencyProfitGetter, agencyProfitPercentagetoolTipValueGetter, amountReceivableWithTaxGetter, campaignCostWithTaxGetter, campaignTypeNameGetter, companyNameGetter, complianceGetter, countryGetter, createdAtGetter, currencyCheck, currencyFormatter, emailGetter, endDateGetter, exchangeProfitGetter, externalDspGetter, fullNameGetter, gatewayLastUpdateGetter, holdingNameGetter, latinAdProfitGetter, partnerProfitGetter, paymentMethodGetter, phoneGetter, platformProfitGetter, platformProfitPercentagetoolTipValueGetter, startDateGetter, taxGetter, userNameGetter, withholdingTaxGetter} from '../strings'

export const columnDefs = ({openPoModal}) => {
    return [
    
        {
          headerName: 'Campaña',
          headerClass: 'column-group-1',
          children: [
            {
              headerName: 'ID',
              field: 'id',
              width: 150,
              filter: true,
              sort: 'desc',
              checkboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              headerCheckboxSelection: true,
              pinned: 'left'
            },
            {
              headerName: 'Nombre Campaña',
              field: 'name',
              filter: true,
              width: 300,
              wrapText: true
            },
            {
              headerName: 'DSP',
              valueGetter: externalDspGetter,
              filter: true,
              width: 200,
              wrapText: true
            },
            {
              headerName: 'Fecha de Creación',
              valueGetter: createdAtGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Fecha de Pago',
              valueGetter: gatewayLastUpdateGetter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Fecha de Inicio',
              valueGetter: startDateGetter,
              filter: true,
              width: 180
            },
            {
              headerName: 'Fecha de Fin',
              valueGetter: endDateGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Duración Real',
              field: 'days_in_sales_period',
              filter: true,
              width: 150,

              colId: 'days_in_sales_period'
            },
            {
              headerName: 'Estado de Pago',
              field: 'payment_status',
              refData: paymentStatusMapping(),
              filter: true,
              width: 150
            },
            {
              headerName: 'Aprobación',
              field: 'status',
              refData: campaignStatusMapping(),
              filter: true,
              width: 150
            },
            {
              headerName: 'Cumplimiento',
              valueGetter: complianceGetter,
              filter: true,
              width: 150,

              colId: 'compliance'
            },
            {
              headerName: 'Tipo',
              field: 'smart_campaign',
              valueGetter: campaignTypeNameGetter,
              filter: true,
              width: 150
            }
          ]
        },
        {
          headerName: 'Operación',
          headerClass: 'column-group-2',
          children: [
            {
              headerName: 'Divisa',
              field: 'currency',
              width: 100,
              filter: true
            },
            {
              headerName: 'Precio Final C/I',
              valueGetter: campaignCostWithTaxGetter,
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 350
            },
            {
              headerName: 'Consumo en rango S/I',
              field: 'total_price_consumed',
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 350,
              colId: 'total_price_consumed',
            },
            {
              headerName: 'Impresiones prometidas',
              field: 'total_promised_shows',
              filter: 'agNumberColumnFilter',
              width: 350,

              colId: 'total_promised_shows'
            },
            {
              headerName: 'Impresiones consumidas en rango',
              field: 'total_shows_consumed',
              filter: 'agNumberColumnFilter',
              width: 350,

              colId: 'total_shows_consumed'
            },
            {
              headerName: 'Precio Final S/I',
              field: 'total_price_with_profit',
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 350
            },
            {
              headerName: 'Ganancia agencia',
              valueGetter: agencyProfitGetter,
              valueFormatter: currencyFormatter,
              tooltipComponent: 'profitPercentageTooltip',
              tooltipValueGetter: agencyProfitPercentagetoolTipValueGetter,
              filter: 'agNumberColumnFilter',
              width: 180
            },
            {
              headerName: 'Ganancia plataforma',
              valueGetter: platformProfitGetter,
              valueFormatter: currencyFormatter,
              tooltipComponent: 'profitPercentageTooltip',
              tooltipValueGetter: platformProfitPercentagetoolTipValueGetter,
              filter: 'agNumberColumnFilter',
              width: 230
            },
            {
              headerName: 'Ganancia LatinAd',
              valueGetter: latinAdProfitGetter,
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              colId: 'latinAdProfit',
              hide: true,
              width: 230
            },
            {
              headerName: 'Ganancia Partner',
              valueGetter: partnerProfitGetter,
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 230
            },
            {
              headerName: 'Ganancia Exchange',
              valueGetter: exchangeProfitGetter,
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 230
            },
            {
              headerName: 'Pago pantalleros',
              field: 'total_price',
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 260
            },
            {
              headerName: 'Monto a cobrar C/I',
              valueGetter: amountReceivableWithTaxGetter,
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 285
            },
            {
              headerName: 'Monto a cobrar S/I',
              field: 'total_price_with_commission',
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 280
            },
            {
              headerName: 'Costos internacionales',
              field: 'withholding_tax_amount',
              valueFormatter: currencyFormatter,
              filter: 'agNumberColumnFilter',
              width: 250
            },
            {
              headerName: 'ODC',
              field: 'purchase_order_agency',
              filter: false,
              width: 120,
              hide: false,
              cellStyle: {textAlign: 'center'},
              cellRenderer: (params) => {
                if (params.value) {
                  const button = document.createElement('a')
                  button.innerText = 'Ver'
                  button.addEventListener('click', () => openPoModal(params.value))
                  return button
                }
              }
            },
            {
              headerName: 'Cotización del día',
              field: 'currency_rate',
              valueFormatter: currencyCheck,
              filter: 'agNumberColumnFilter',
              width: 250
            }
          ]
        },
        {
          headerName: 'Impuestos',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'Local',
              valueGetter: taxGetter,
              width: 150
            },
            {
              headerName: 'Internacional',
              valueGetter: withholdingTaxGetter,
              width: 150
            }
          ]
        },
        {
          headerName: 'Facturación',
          headerClass: 'column-group-3',
          children: [
            {
              headerName: 'País',
              valueGetter: countryGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Nombre Cliente',
              valueGetter: fullNameGetter,
              filter: true,
              width: 200
            },
            {
              headerName: 'Tax ID Cliente',
              field: 'billing_information_doc_number',
              filter: true,
              width: 150
            },
            {
              headerName: 'Email Cliente',
              valueGetter: emailGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Teléfono Cliente',
              valueGetter: phoneGetter,
              filter: true,
              width: 150
            },
            {
              headerName: 'Forma de Pago',
              valueGetter: paymentMethodGetter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Regional',
              field: 'multi_country',
              width: 120,
              hide: false,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'}
            },
            {
              headerName: 'Regional',
              field: 'multi_country',
              hide: true
            },
            {
              headerName: 'Facturado',
              field: 'invoiced',
              cellRenderer: 'invoicedRenderer',
              width: 120,
              hide: false,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
              pinned: 'left',
              filter: true,
              refData: campaignInvoicesMapping()
            },
            {
              headerName: 'Facturado',
              field: 'invoiced',
              hide: true
            },
            {
              headerName: 'Cobrado',
              field: 'charged',
              cellRenderer: 'chargedRenderer',
              width: 120,
              hide: false,
              cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
              pinned: 'left',
              filter: true,
              refData: campaignChargesMapping()
            },
            {
              headerName: 'Cobrado',
              field: 'charged',
              hide: true
            }
          ]
        },
        {
          headerName: 'Usuario',
          headerClass: 'column-group-4',
          children: [
            {
              headerName: 'Responsable Agencia',
              valueGetter: userNameGetter,
              filter: true,
              width: 250
            },
            {
              headerName: 'Email Responsable',

              field: 'user_email',
              filter: true,
              width: 250
            },
            {
              headerName: 'Agencia',
              valueGetter: companyNameGetter,
              filter: true,
              width: 300
            },
            {
              headerName: 'Holding',
              valueGetter: holdingNameGetter,
              filter: true,
              width: 200
            }
          ]
        }
]}