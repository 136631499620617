var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "SSRAgTable",
        _vm._g(
          {
            ref: "agTable",
            attrs: {
              frameworkComponents: _vm.frameworkComponents,
              columns: _vm.columnDefs,
              data: _vm.rowData,
              totalRows: _vm.totalRows,
              totalPages: _vm.totalPages,
              initialRenderered: false,
            },
            on: {
              fetchData: _vm.getGridData,
              updateSelectedItems: _vm.onSelectedItems,
            },
          },
          _vm.$listeners
        ),
        [
          _c(
            "template",
            { slot: "extraHeaderActions" },
            [
              this.countSelected
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can",
                          value: "change_panel_invoiced_campaign",
                          expression: "'change_panel_invoiced_campaign'",
                        },
                      ],
                      staticClass: "mb-4 md:mb-0 mr-4",
                      on: {
                        click: function ($event) {
                          return _vm.openPopupChangeState("invoiced")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          Cambiar estado facturación (" +
                          _vm._s(this.countSelected) +
                          ")\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              this.countSelected
                ? _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "can",
                          rawName: "v-can",
                          value: "change_panel_charged_campaign",
                          expression: "'change_panel_charged_campaign'",
                        },
                      ],
                      staticClass: "mb-4 md:mb-0 mr-4",
                      on: {
                        click: function ($event) {
                          return _vm.openPopupChangeState("charged")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        Cambiar estado cobrado (" +
                          _vm._s(this.countSelected) +
                          ")\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.popupActiveChangeStateTitle,
            active: _vm.popupActiveChangeState,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupActiveChangeState = $event
            },
          },
        },
        [
          _c(
            "vs-table",
            {
              attrs: { noDataText: "Error", data: _vm.selectedRows },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr },
                        [
                          _c("vs-td", { attrs: { data: data[indextr].id } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(data[indextr].id) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { attrs: { data: data[indextr].name } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(data[indextr].name) +
                                "\n          "
                            ),
                          ]),
                          _vm.popupActiveChangeStateType == "invoiced"
                            ? _c(
                                "vs-td",
                                {
                                  attrs: { data: data[indextr].type_campaign },
                                },
                                [
                                  data[indextr].invoiced
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("FACTURADA")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO FACTURADA")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.popupActiveChangeStateType == "invoiced"
                            ? _c(
                                "vs-td",
                                {
                                  attrs: { data: data[indextr].type_campaign },
                                },
                                [
                                  !data[indextr].invoiced
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("FACTURADA")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO FACTURADA")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.popupActiveChangeStateType == "charged"
                            ? _c(
                                "vs-td",
                                { attrs: { data: data[indextr].start_date } },
                                [
                                  data[indextr].charged
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("COBRADO")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO COBRADO")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.popupActiveChangeStateType == "charged"
                            ? _c(
                                "vs-td",
                                { attrs: { data: data[indextr].start_date } },
                                [
                                  !data[indextr].charged
                                    ? _c(
                                        "vs-chip",
                                        { attrs: { color: "success" } },
                                        [_vm._v("COBRADO")]
                                      )
                                    : _c(
                                        "vs-chip",
                                        { attrs: { color: "danger" } },
                                        [_vm._v("NO COBRADO")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [_vm._v("ID")]),
                  _c("vs-th", [_vm._v("Nombre")]),
                  _c("vs-th", [_vm._v("Estado actual")]),
                  _c("vs-th", [_vm._v("Nuevo estado")]),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "vs-button",
            {
              staticClass: "float-right mt-2",
              attrs: { color: "primary", type: "filled" },
              on: {
                click: function ($event) {
                  return _vm.changeState()
                },
              },
            },
            [_vm._v("Cambiar")]
          ),
        ],
        1
      ),
      _c("show-docs-popup", {
        attrs: {
          poModalActive: _vm.poModalActive,
          poModalData: _vm.poModalData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }